.wrapper {
  position: relative;

  & > img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.corner {
  position: absolute;
  width: 0.3125rem;
  height: 0.3125rem;
  background: var(--background);
  z-index: 1;

  &--left-top,
  &--left-bottom {
    left: 0;
  }

  &--right-top,
  &--right-bottom {
    right: 0;
  }

  &--right {
    &-top {
      top: 0;
    }

    &-bottom {
      bottom: 0;
    }
  }

  &--left {
    &-top {
      top: 0;
    }

    &-bottom {
      bottom: 0;
    }
  }
}
