.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 1.25rem;
}

.tag {
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.text {
  display: inline-block;
  position: relative;
  bottom: -0.075em;
}
