.wrapper {
  padding: 3.125rem 0;

  &:first-of-type,
  & + & {
    padding-top: 0.5rem;
  }

  &:last-child {
    margin-top: 0;
  }

  &.topPadding {
    padding-top: 3.125rem;
  }
}

.banner {
  margin-top: 1.875rem;
}

.slide {
  position: relative;
  display: inline-flex;
  height: auto;
  opacity: 0;
  transition: 0.2s ease-in-out;
  padding-top: 2.1875rem;

  &:not(:last-child) {
    margin-right: min(3.4722vw, 50px);

    &:after {
      position: absolute;
      left: calc(100% + min(3.4722vw, 50px) / 2);
      top: 0;
      width: 1px;
      height: 20px;
      background-color: var(--border);
      opacity: 0;
      visibility: hidden;
      transition: inherit;
      content: '';
    }
  }

  &:global(.swiper-slide-active),
  &:global(.swiper-slide-active) + &,
  &:global(.swiper-slide-active) + & + &,
  &.six:global(.swiper-slide-active) + & + & + &,
  &.six:global(.swiper-slide-active) + & + & + & + & {
    opacity: 1;
    visibility: visible;

    &:after {
      opacity: 0.8;
      visibility: visible;
    }
  }

  &.six:global(.swiper-slide-active) + & + & + & + & + & {
    opacity: 1;
    visibility: visible;

    &:after {
      opacity: 0.8;
    }
  }

  &.twoSlides {
    min-width: 400px;
    max-width: calc((100% - min(3.4722vw, 50px)) / 2);
  }

  &.three {
    min-width: 255px;
    max-width: calc((100% - min(3.4722vw, 50px) * 2) / 3);

    &:global(.swiper-slide-active) + & + &:after {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.six {
    min-width: 175px;
    max-width: calc((100% - min(3.4722vw, 50px) * 5) / 6);
  }
}

.item {
  width: 100%;
}

.slider {
  overflow: visible;
  margin-top: 1.25rem;
  border-top: 1px solid var(--border);

  :global(.swiper-wrapper) {
    display: flex;
  }
}

.safariFix {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  -moz-backdrop-filter: blur(3.5px);
}

@media (max-width: 70rem) {
  .slide {
    &.oneOnMobile {
      max-width: 50% !important;
    }

    &.twoSlides {
      max-width: calc((100% - 15px) / 2);
    }

    &.three {
      opacity: 1;
      visibility: visible;
      max-width: calc((100% - 15px * 2) / 3);
    }

    &.six {
      max-width: calc((100% - 15px * 5) / 6);
    }

    &:not(:last-child) {
      margin-right: 0.9375rem;

      &:after {
        display: none;
      }
    }
  }
}

@media (max-width: 67.5rem) {
  .slide.twoSlides {
    min-width: 350px;
  }
}

@media (max-width: 62rem) {
  .wrapper {
    padding: 1.25rem 0;

    &.topPadding {
      padding-top: 1.625rem;
    }
  }

  .lastSlider {
    padding-bottom: 1.875rem;
  }

  .slider {
    border: none;
    margin: 0;
  }

  .slide {
    padding-top: 1.25rem;
    opacity: 1;
    visibility: visible;

    &.twoSlides {
      min-width: 255px;
      max-width: calc((100% - min(3.4722vw, 50px) * 2) / 3) !important;
    }

    .six {
      min-width: 165px;
      max-width: calc((100% - min(3.4722vw, 50px)) / 2) !important;
    }

    &.oneOnMobile {
      max-width: 50% !important;
    }

    &:not(:last-child) {
      margin-right: 0.9375rem;

      &:after {
        display: none;
      }
    }
  }
}

@media (max-width: 48rem) {
  .slide {
    &.oneOnMobile {
      max-width: 100% !important;
    }
  }
}

@media (max-width: 28rem) {
  .slide {
    &.six {
      min-width: 165px;
    }
  }
}
