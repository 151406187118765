@function scale($value, $factor) {
  @return $value * $factor;
}

$scale-factor: 1;

@mixin responsive-scales {
  @content;

  @media (max-width: 48rem) {
    $scale-factor: 1.3 !global;
    @content;
    $scale-factor: 1 !global;
  }

  @media (min-width: 116.25rem) {
    $scale-factor: 0.9 !global;
    @content;
    $scale-factor: 1 !global;
  }

  @media (min-width: 166rem) {
    $scale-factor: 0.8 !global;
    @content;
    $scale-factor: 1 !global;
  }

  @media (min-width: 187.5rem) {
    $scale-factor: 0.7 !global;
    @content;
    $scale-factor: 1 !global;
  }

  @media (min-width: 218rem) {
    $scale-factor: 0.6 !global;
    @content;
    $scale-factor: 1 !global;
  }

  @media (min-width: 250rem) {
    $scale-factor: 0.4 !global;
    @content;
    $scale-factor: 1 !global;
  }

  @media (min-width: 375rem) {
    $scale-factor: 0.2 !global;
    @content;
    $scale-factor: 1 !global;
  }
}

.container {
  position: relative;
  overflow: hidden;
}

.grid {
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.iconContainer,
.animatedSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  font-weight: 600;
}

.animatedSpan {
  will-change: opacity;
  color: var(--contrast);
}

.iconContainer svg {
  align-items: center;
  justify-content: center;
  display: flex;
}

.tg {
  grid-row: 6;
  grid-column: 2;
  opacity: 1;
}

.dLogo {
  grid-row: 2;
  grid-column: 22;
  opacity: 1;
}

.union {
  grid-row: 6;
  grid-column: 31;
  opacity: 1;
}

@include responsive-scales {
  .container {
    width: scale(70.3675vw, $scale-factor);
    height: scale(23.3vw, $scale-factor);
  }

  .grid {
    grid-template-columns: repeat(41, scale(1.7vw, $scale-factor));
    grid-row-gap: scale(0.8vw, $scale-factor);
  }

  .iconContainer,
  .animatedSpan {
    width: scale(1.8vw, $scale-factor);
    height: scale(1.05vw, $scale-factor);
    font-size: scale(1.2vw, $scale-factor);
  }

  .iconContainer svg {
    width: scale(1.8vw, $scale-factor);
    height: scale(1.05vw, $scale-factor);
  }
}
