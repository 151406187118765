.wrapper {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-bottom: 2rem;

  &::after {
    content: '';
    display: block;
    height: 0.25rem;
    background-color: var(--text);
    width: 100%;
    margin-top: 3.125rem;
  }

  &::before {
    content: '';
    display: block;
    height: 1px;
    background-color: var(--text);
    width: 100%;
  }

  .error {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    color: var(--text);
    letter-spacing: -0.03em;

    h1 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .errorImage {
    margin: 4.6875rem auto 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    height: auto;

    :global(.stroke-revert) {
      stroke: var(--background);
    }

    :global(.revert) {
      fill: var(--background);
    }

    :global(.corner) {
      fill: var(--background);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .button {
    background: none;
    border: 1px solid var(--text);
    margin-top: 5.625rem;

    color: var(--text);
    padding: 1rem 1.5rem;
    width: fit-content;
    font-size: 1rem;
    letter-spacing: -0.01em;
    cursor: pointer;
  }
}

@media (max-width: 62rem) {
  .wrapper {
    margin-top: 2.5rem;

    .button {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
    }
  }
}

@media (max-width: 28rem) {
  .wrapper .error {
    flex-direction: column-reverse;
    gap: 1rem;

    .code {
      font-size: 2.25rem;
      line-height: 1.11;
      letter-spacing: -0.04em;
    }
  }
}
