.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleLink {
  display: block;
  flex-grow: 1;
}

.title {
  flex-grow: 1;
  margin: 0;
  color: var(--text);
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 500;

  svg {
    display: inline-block;
    transform: translate3d(0, 0.05em, 0);
    max-width: 2.5em;
    height: auto;
    margin-left: 0.2em;
    flex-shrink: 0;
  }
}

.total {
  letter-spacing: -0.01em;
  font-weight: 700;
  font-size: 0.8125rem;
  top: -1em;
  right: 0.2em;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.button {
  padding: 0.5rem 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text);

  svg {
    display: block;

    path {
      fill: var(--text);
    }
  }

  &:not(.next) svg {
    transform: rotate(90deg);
  }
}

:global(body.dark) {
  .button svg path {
    fill: var(--gray3);
  }
}

.next {
  transform: rotate(270deg);
}

.line {
  width: 0.0625rem;
  height: 1.125rem;
  background: var(--text);
}

@media (max-width: 62rem) {
  .row {
    padding-bottom: 0.625rem;
    column-gap: 1rem;
    border-bottom: 1px solid var(--border);
  }
}

@media (max-width: 22rem) {
  .title {
    font-size: 1em;
  }
  .row {
    gap: 0.625rem;
  }
}
