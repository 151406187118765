.link {
  display: block;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
}

.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1.25rem;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 175/117;
}

.title {
  display: inline-block;
  margin: 0.625rem 0 0;
  line-height: 1.3;
  letter-spacing: -0.03em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: 2px;
  span {
    border-bottom: 1px solid transparent;
  }
}

.bigTitle {
  margin: 0.8125rem 0 0;
}

.date {
  opacity: 0.6;
}

.views {
  margin-left: auto;
}

.posts {
  display: block;
  line-height: 1.3;
  letter-spacing: -0.03em;
  font-weight: 400;
  opacity: 0.6;
}

@media (min-width: 62.0001rem) {
  .image {
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  .image,
  .title span {
    transition: 0.25s ease-out;
  }
  .post:hover {
    .title span {
      transition: 0.2s ease-in;
      border-bottom-color: inherit;
    }

    img {
      transform: scale(1.1);
    }
  }
}

@media (max-width: 60rem) {
  ul.tag {
    display: none;
  }
}

@media (max-width: 28rem) {
  .imageWrapper {
    margin-bottom: 1.25rem;
  }
}
